<template>
  <v-container class="mt-6">
    <div class="detailsWrapper">
      <v-row class="rowWrapper mb-6"> Medicare </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="medicareNumber"
            label="Medicare number"
            placeholder=" "
            readonly
            persistent-placeholder
          />
        </v-col>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="studentDetails.medicareCardRef"
            label="Medicare card reference number"
            placeholder=" "
            readonly
            persistent-placeholder
          />
        </v-col>
        <v-col cols="12" md="4">
          <AdsTextField
            :value="studentDetails.medicareExpiryDate"
            label="Medicare card valid to date"
            placeholder=" "
            readonly
            persistent-placeholder
          />
        </v-col>
      </v-row>
    </div>
    <!-- Doctor -->
    <div v-if="doctorDetails && doctorDetails.length > 0">
      <v-card
        v-for="(doctor, index) in doctorDetails"
        :key="index"
        class="doctorDetails"
      >
        <v-row class="rowWrapper mb-6"> Doctor </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="doctor.doctorName"
              label="Doctor's name/medical centre"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="getDoctorAddress(doctor.doctorAddress)"
              label="Doctor Address"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="displayFormatPhoneNumber(doctor.doctorPhoneNumber)"
              label="Phone number"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
      </v-card>
    </div>
    <!-- Allergies -->
    <div v-if="allergies && allergies.length > 0">
      <v-card
        v-for="(allergy, index) in allergies"
        :key="index"
        class="doctorDetails"
      >
        <v-row class="rowWrapper mb-6">
          {{ getAllergyHeading(allergy) }}
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="allergyName(allergy)"
              label="Allergic to"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextArea
              :value="allergy.notes"
              label="Notes"
              placeholder=" "
              counter="2048"
              readonly
              class="pr-5"
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="displayYesOrNoValue(allergy.severeAllergy)"
              label="Severe allergy (anaphylaxis)?"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="displayYesOrNoValue(allergy.doctorDiagnosed)"
              label="Diagnosed by a doctor?"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="allergy.doctor.doctorName"
              label="Doctor"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="displayYesOrNoValue(allergy.hasHospitalised)"
              label="Has been hospitalised with this condition?"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="allergy.hospitalName"
              label="Hospital name"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="displayYesOrNoValue(allergy.asciaActionPlanInd)"
              label="Has an ASCIA Action Plan for Anaphylaxis?"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="displayYesOrNoValue(allergy.healthCarePlanInd)"
              label="Has individual health care plan?"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="displayYesOrNoValue(allergy.hasAutoinjector)"
              label="Adrenaline autoinjector?"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <v-card
          v-for="(medication, mindex) in allergy.medications"
          :key="mindex"
          class="ml-4 mr-4"
        >
          <v-row class="rowWrapper mb-6"> Medication </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="medication.medicationName"
                label="Medication name"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="getAdministeredby(medication.medicationAdministeredBy)"
                label="Administered by"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="medication.medicationCategory"
                label="Medication type"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="medication.dosage"
                label="Dosage"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="
                  autoinjectorExpiryDate(medication.autoinjectorExpiryDate)
                "
                label="Expiry date"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextArea
                :value="medication.notes"
                label="Notes"
                placeholder=" "
                counter="2048"
                readonly
                class="pr-5"
                persistent-placeholder
              />
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </div>
    <!-- Medical Condition -->
    <div v-if="medicalCondition && medicalCondition.length > 0">
      <v-card
        v-for="(medicalConditionItem, index) in medicalCondition"
        :key="index"
        class="doctorDetails"
      >
        <v-row class="rowWrapper mb-6">
          {{
            getMedicalConditionHeading(
              medicalConditionItem.medicalConditionName
            )
          }}
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="medicalConditionItem.medicalConditionName"
              label="Medical condition"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextArea
              :value="medicalConditionItem.notes"
              label="Notes"
              placeholder=" "
              readonly
              counter="2048"
              class="pr-5"
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="displayYesOrNoValue(medicalConditionItem.doctorDiagnosed)"
              label="Diagnosed by a doctor?"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="displayYesOrNoValue(medicalConditionItem.hasHospitalised)"
              label="Has been hospitalised with this condition?"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="
                displayYesOrNoValue(medicalConditionItem.asciaActionPlanInd)
              "
              label="Has an Action Plan for this medical condition?"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
          <v-col cols="12" md="4">
            <AdsTextField
              :value="
                displayYesOrNoValue(medicalConditionItem.healthCarePlanInd)
              "
              label="Has individual health care plan?"
              placeholder=" "
              readonly
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <v-card
          v-for="(medication, mindex) in medicalConditionItem.medications"
          :key="mindex"
          class="ml-4 mr-4"
        >
          <v-row class="rowWrapper mb-6"> Medication </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="medication.medicationName"
                label="Medication name"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="getAdministeredby(medication.medicationAdministeredBy)"
                label="Administered by"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="medication.medicationCategory"
                label="Medication type"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <AdsTextField
                :value="medication.dosage"
                label="Dosage"
                placeholder=" "
                readonly
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <AdsTextArea
                :value="medication.notes"
                label="Notes"
                placeholder=" "
                readonly
                counter="2000"
                class="pr-5"
                persistent-placeholder
              />
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { AdsTextField, AdsTextArea } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { COUNTRIES, ADMINISTEREDBY } from '@/constants'
import {
  yesOrNoFromBooleanOrString,
  displayDateFormat,
  formatPhoneNumber
} from '@/utils/general'

export default {
  name: 'ParentCarerDetails',
  components: { AdsTextField, AdsTextArea },
  computed: {
    ...mapGetters(['selectedErnStudentInfo']),
    studentDetails() {
      return this.selectedErnStudentInfo?.student || {}
    },
    medicareNumber() {
      return this.studentDetails.medicareNumber
        ? this.studentDetails.medicareNumber
            .trim()
            .replace(/\W/gi, '')
            .replace(/(.{4})(.{5})/g, '$1 $2 ')
        : ''
    },
    doctorDetails() {
      return this.selectedErnStudentInfo?.doctorDetails
    },
    allergies() {
      return this.selectedErnStudentInfo?.allergies
    },
    medicalCondition() {
      return this.selectedErnStudentInfo?.nonAllergies
    }
  },
  methods: {
    getDoctorAddress(doctorAddress) {
      const countryCode = doctorAddress?.countryCode || ''
      const address =
        doctorAddress?.addressLine1 || doctorAddress?.addressLine2 || ''
      const suburbName = doctorAddress?.suburbName || ''
      const stateCode = doctorAddress?.stateCode || ''
      const postCode = doctorAddress?.postCode || ''
      const country =
        COUNTRIES.find((country) => country.code === countryCode)?.value || ''
      return countryCode === 'AUS'
        ? [address, suburbName, stateCode, postCode].filter(Boolean).join(', ')
        : [address, country].filter(Boolean).join(', ')
    },
    allergyName(allergy) {
      return (
        _.capitalize(allergy.medicalConditionName?.split('-')[1].trim()) || ''
      )
    },
    getAllergyHeading(allergy) {
      if (!allergy?.medicalConditionName) {
        return ''
      }

      if (allergy?.severeAllergy === true) {
        return `Anaphylaxis - ${this.allergyName(allergy)}`
      }
      if (allergy?.severeAllergy === false) {
        return `Allergy - ${this.allergyName(allergy)}`
      }
      return `Allergy/Anaphylaxis - ${this.allergyName(allergy)}`
    },
    displayYesOrNoValue(value) {
      return yesOrNoFromBooleanOrString(value)
    },
    autoinjectorExpiryDate(expireDate) {
      return displayDateFormat(expireDate)
    },
    getMedicalConditionHeading(medicalConditionName) {
      return medicalConditionName
        ? `Medical condition - ${medicalConditionName}`
        : 'Medical condition'
    },
    getAdministeredby(administeredby) {
      return (
        ADMINISTEREDBY.find((val) => val.code === administeredby).value || ''
      )
    },
    displayFormatPhoneNumber(phoneNumber) {
      return formatPhoneNumber(phoneNumber)
    }
  }
}
</script>

<style lang="scss" scoped>
.detailsWrapper {
  padding: 1rem;
  margin-top: -2rem !important;
}

.doctorDetails {
  box-shadow: 0 1px 3px #c5c5c5;
  margin: 1rem;
  padding-bottom: 20px;
}

.rowWrapper {
  font-weight: bold;
  font-size: 16px;
  color: $color-text-body;
  overflow-wrap: break-word;
  margin: 2rem;
  padding-top: 1rem !important;
}

::v-deep .v-input__slot fieldset {
  border: none;
}

::v-deep .col-md-4,
.col-12 {
  padding: 0 !important;
}
</style>
